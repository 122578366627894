<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                复旦大学 DS&BA 项目启动，非凸联合创始人李佐凡担任硕士职业导师
              </p>
              <div class="fst-italic mb-2">2022年11月9日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >校企合作</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/19/01-复旦项目.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技与复旦大学管理学院展开“产学研教”深入合作，希望通过数据科学与商务分析（DS&BA）项目，提高学生的实践能力和应用能力，从而为量化策略研究赋能。
                  </p>
                   <p>
                    DS&BA项目，旨在培养学生应用定量方法处理与分析复杂大数据等方面的能力；而D-Lab项目是DS&BA项目培养中的必修环节，一门为期3个月的实践课程，旨在指导学生实现数据清洗、算法应用与开发，并针对实际业务问题，做出有针对性的数据分析方案。
                  </p>
                  <p>
                    非凸科技联合创始人&CTO李佐凡，受聘为复旦大学管理学院数据科学与商业分析专业硕士职业导师。与此同时，作为此次DS&BA与D-Lab项目导师，将联合学院导师共同指导学生完成实践课题项目。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/19/02-职业导师.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    技术迭代更新，大数据、人工智能等技术应用到量化投资领域，不仅给投资机构创造了新的市场机会，也给中国高校金融及相关专业的教学改革提供了新思路。此次，非凸科技与校方合作，将发挥各自资源优势，培养学生的实践能力，使他们能够利用前沿的技术手段，将课堂理论转化为实践，贴近实际，贴近市场，提前做好进入金融市场的准备。
                  </p>
                  <p>
                    非凸科技也将在关键核心技术攻关与技术创新、课题项目成果转化、人才培养等方面继续发力，旨在推动校企之间更丰富、更深层的合作，为学生提供实践、探究的空间和条件，从而实现校企共赢新局面。
                  </p>
                  <p>
                    在致力于不断精进、不断突破技术的同时，非凸科技也期待越来越多志同道合的同学加入进来，一起拼搏进取、追求卓越！
                  </p>
                 
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News19",
};
</script>

<style></style>
